<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item :to="{ path: '/manage/paper' }">试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ paperBankName ? paperBankName : '' }}试卷列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-input v-model="searchKey" placeholder="通过试卷名称模糊搜索" class="yt-search">
          <i slot="suffix" class="el-input__icon el-icon-search" />
        </el-input>
        <div style="display: flex" v-if="activeIndex === true">
          <Dropdown @on-click="handleDropdownClicked">
            <el-button type="primary" size="small">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <p>新建试卷</p>
              </div>
            </el-button>
            <DropdownMenu slot="list">
              <DropdownItem name="manual">手动出卷</DropdownItem>
              <DropdownItem name="auto">自动出卷</DropdownItem>
              <DropdownItem name="rand">随机出卷</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <el-button style="margin-left: 10px !important" size="small" @click="delPapers1">
            <div>
              <YTIcon :href="'#icon-shanchu'" />
              <p>删除试卷</p>
            </div>
          </el-button>
          <el-button size="small" style="width:136px" @click="toPaperDesignated">
            <div>
              <YTIcon :href="'#icon-chakan'" />
              <p>查看所有考试</p>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <el-table class="yt-table" :data="rowData" height="calc(100% - 53px)" @selection-change="selectedChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="试卷名称" show-overflow-tooltip />
          <el-table-column prop="paperId" label="试卷编号" show-overflow-tooltip />
          <el-table-column prop="totalPoints" label="总分" show-overflow-tooltip />
          <el-table-column label="题量" prop="questionAmount" show-overflow-tooltip> </el-table-column>
          <el-table-column label="难度" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ checkLevel(scope.row.level) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="usedTimes" label="使用次数" show-overflow-tooltip />
          <el-table-column label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="dot" :style="{ backgroundColor: colorEnum[scope.row.status] }"></span>
              <span :style="{ color: colorEnum[scope.row.status] }">{{ statusEnum[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ $formatTime(scope.row.createTime, 'yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="修改时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ $formatTime(scope.row.updateTime, 'yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="creatorName" label="创建人" show-overflow-tooltip />
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <span class="tool-button" v-if="activeIndex === true"><span @click.stop="handleRel(scope.row)">发布</span></span>
              <span class="tool-button"><span @click.stop="toDetail(scope.row)">查看</span></span>
              <template v-if="activeIndex === true">
                <el-dropdown placement="bottom-start">
                  <div class="tool-button-more">
                    <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><span @click="delPaper(scope.$index)">删除</span></el-dropdown-item>
                    <el-dropdown-item><span @click.stop="handleSee(scope.row)">考试</span></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else>
                <span class="tool-button" style="margin-left: 10px" v-if="scope.row.type !== 2"
                  ><span @click.stop="CopyPaper(scope.row)">复制</span></span
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (totalPage.toString().length > 1 ? totalPage.toString().length : 2)"
          :total="totalPage"
          :page-size="pageSize"
          :page-size-opts="[10, 20, 40, 100]"
          show-total
          show-elevator
          show-sizer
          @on-change="handlePageChange"
          @on-page-size-change="changeSize"
        />
      </div>
      <CopyPaperCUDialog ref="CopyPaper"></CopyPaperCUDialog>
      <ConfirmDialog ref="deletePaperBankModal" :title="'删除试卷'" @on-ok="handleDelete">
        <div style="width: 219px; margin: 0 auto; ">
          <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
          删除后该选中试卷不可恢复, 您还要继续吗？
        </div>
      </ConfirmDialog>
      <StaffSelectDialog ref="StaffSelect" style="min-width: 1080px" @success="onsuccess"></StaffSelectDialog>
      <el-dialog
        :visible.sync="showRel"
        title="发布考试"
        :close-on-click-modal="false"
        center
        class="add-modal"
        :before-close="close"
        @closed="cancelRel"
      >
        <Form ref="sendExamForm" :model="relForm" :rules="rules" :label-width="135">
          <FormItem label="考试名称" prop="name">
            <Input v-model="relForm.name" placeholder="请输入考试名称" />
          </FormItem>
          <FormItem label="考试简介" prop="description">
            <Input v-model="relForm.description" placeholder="请输入考试简介" />
          </FormItem>
          <FormItem label="考试时长(分钟)" prop="limitTime">
            <InputNumber v-model="relForm.limitTime" placeholder="请输入考试时长(分钟)" :min="1" :max="999" style="width: 100%" />
          </FormItem>
          <FormItem label="通过比例(0~1)" prop="passRate">
            <InputNumber v-model="relForm.passRate" placeholder="请输入考试通过分数" :min="0.1" :max="1" :step="0.1" style="width: 100%" />
          </FormItem>
          <FormItem label="用户端考试详情查看">
            <el-radio-group v-model="relForm.isView">
              <el-radio :label="0">可查看</el-radio>
              <el-radio :label="1">不可查看</el-radio>
            </el-radio-group>
          </FormItem>
          <FormItem label="用户端考试报告下载">
            <el-radio-group v-model="relForm.isDownload">
              <el-radio :label="0">可下载</el-radio>
              <el-radio :label="1">不可下载</el-radio>
            </el-radio-group>
          </FormItem>
          <FormItem label="是否可以重考">
            <el-radio-group v-model="relForm.repeatState">
              <el-radio :label="1">可重考</el-radio>
              <el-radio :label="0">不可重考</el-radio>
            </el-radio-group>
          </FormItem>
          <FormItem v-if="relForm.repeatState === 1" label="设置重考次数" prop="passRate">
            <div style="position:relative;">
              <el-input size="small" @change="validateNum" v-model="relForm.repeatNum" placeholder="请设置重考次数" style="width: 100%">
                <template slot="suffix">次</template>
              </el-input>
              <span v-show="relForm.repeatNum === 0" style="color: red;position:absolute;top:30px;left: 0;">提示: 输入0表示可以无限次重考</span>
            </div>
          </FormItem>
          <FormItem label="选择用户">
            <el-checkbox v-model="disUser" @change="choseDisCuser">指定用户</el-checkbox>
          </FormItem>
          <FormItem v-if="disUser" label="指定用户">
            <el-button :loading="deUserListQ.IsWait" size="mini" type="primary" @click="chosUsers()">选择</el-button>
            &nbsp;&nbsp;<i>已选: {{ relForm.studentIds.length }}用户</i>
          </FormItem>
          <FormItem label="开始时间">
            <el-date-picker
              v-model="relForm.startTime"
              type="datetime"
              size="small"
              style="width: 200px"
              :picker-options="{ disabledDate: checkStartDate }"
              @change="checkStartTime"
              placeholder="选择日期和时间"
            >
            </el-date-picker>
          </FormItem>
          <FormItem label="结束时间">
            <el-date-picker
              v-model="relForm.endTime"
              type="datetime"
              size="small"
              style="width: 200px"
              :picker-options="{ disabledDate: checkStartDate }"
              @change="checkStartDate(relForm.endTime)"
              @blur="checkStartDate(relForm.endTime)"
              placeholder="选择日期和时间"
            >
            </el-date-picker>
          </FormItem>
        </Form>
        <div slot="footer" class="footer">
          <Button @click="cancelRel" class="modal-btn" style="margin-right: 10px;">取消</Button>
          <Button :loading="IsRelease" type="primary" class="modal-btn" @click="confirmRel('sendExamForm')">发布</Button>
        </div>
      </el-dialog>
      <el-dialog class="deUsersModal" :visible.sync="userModal" title="选择用户" width="45%" top @closed="closedDeUsers">
        <div style="display: flex;justify-content: flex-start;margin-bottom: 15px">
          <el-input
            style="width: 300px;"
            size="small"
            placeholder="请输入姓名(回车搜索)"
            v-model="deUserListQ.keyword"
            @keyup.native.enter="getdePUsers"
          />
        </div>
        <div class="overStyle" style="max-height: 500px; overflow-y: auto">
          <el-table ref="deUsersTable" :data="depaUsers" style="width: 100%" :row-key="getRowKeys" border @selection-change="handleSelect">
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column label="工号" width="60" align="center" prop="jobNumber" />
            <el-table-column label="岗位" align="200">
              <template slot-scope="scope"> {{ scope.row.postVO.postName }} </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" prop="realName" />
          </el-table>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: space-between">
          <Page size="small" show-total @on-change="handlePageNum" @on-page-size-change="handlePageSize" show-sizer :total="deTotal"> </Page>
          <div>
            <el-button size="mini" @click="userModal = false" type="primary">确定</el-button>
          </div>
        </div>
      </el-dialog>
      <RandomDialog ref="Random" :bankId="bankId" @on-ok="handleSearchPaper"></RandomDialog>
    </div>
  </div>
</template>
<script>
import JYCard from '@components/common/JYCard'
import JYSimpleButton from '@components/common/JYSimpleButton'
import JYStatus from '@components/common/JYStatus'
import ExamApi from '@api/exam'
import PaperAPI from '@api/paper'
import DeleteModal from '@components/common/DeleteModal'
import { EXAM_STATUS } from '@/util/examStatus'
import { mapMutations } from 'vuex'
import { difficulty } from '@/util/difficulty'
import PaperBankCard from './PaperBankCard'
import { throttle } from '@util/throttle'
import departmentAPI from '../../../../api/department'
import staffApi from '@api/staff'
import YTIcon from '@/components/common/YTIcon'
import ConfirmDialog from '@/components/common/dialog/ConfirmDialog'
import StaffSelectDialog from '@/components/common/dialog/StaffSelectDialog'
import CopyPaperCUDialog from '@/components/common/dialog/PaperCopyDialog'
import RandomDialog from '@components/manage/exam/paper/RandomDialog'
const defaultDeUser = {
  departmentId: 127,
  page: 0,
  size: 10,
  IsWait: false,
  keyword: null
}
const defaultSearchU = {
  pageParamForm: {
    pageNumber: 0,
    pageSize: 999
  },
  sortCriteria: {},
  userCriteria: {
    email: '',
    phone: '',
    realName: null,
    status: 0
  }
}
const defaultRelForm = {
  repeatState: 0, // 0 不可以重考
  repeatNum: null, // 重考时设置次数  不可重考是null  可以重考最低是1
  courseId: 1,
  description: '',
  endTime: '',
  limitTime: 100,
  messageAfterExam: '',
  messageBeforeExam: '',
  name: '',
  isView: 1,
  isDownload: 1,
  paperId: 0,
  passRate: 0.6,
  repeated: 1,
  startTime: '',
  studentIds: [],
  type: 1,
  scope: 0
}
export default {
  name: 'PaperList',
  mounted() {},
  props: {
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.userId
      },
      startD: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      endD: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      statusEnum: ['UNKNOWN', '正常', '草稿', '已删除', '已删除'],
      colorEnum: ['', '#4CE47A', '#CCC', '#FFC117', '#FF5050'],
      activeIndex: true,
      PaperBankName: '',
      //旧的变量
      selectedExamId: [],
      rowData: [],
      pageSize: 10,
      currentPage: 0,
      paperId: '',
      totalPage: 0,
      tableLoading: false,
      showDeleteConfirmation: false,
      isProcessingDelete: false,
      tobeDeletedIndex: 0,
      searchKey: '',
      paperBankId: 0,
      showRel: false,
      relForm: Object.assign({}, defaultRelForm),
      delIds: [],
      mulSelUsers: [],
      depaUsers: [],
      deTotal: 0,
      row: null,
      disUser: 0,
      bankId: '',
      userModal: false,
      IsRelease: false,
      searchUser: Object.assign({}, defaultSearchU),
      deUserListQ: Object.assign({}, defaultDeUser),
      rules: {
        name: [
          { required: true, message: '请设置考试名称', trigger: 'blur' },
          { max: 30, message: '考试名名称不得超过30个字', trigger: 'blur' }
        ],
        description: {
          max: 30,
          message: '考试名简介不得超过30个字',
          trigger: 'blur'
        },
        limitTime: [
          {
            type: 'number',
            required: true,
            message: '请设置考试时长',
            trigger: 'blur'
          },
          {
            type: 'number',
            max: 999,
            message: '已达考试时长上限',
            trigger: 'blur'
          }
        ],
        passRate: {
          type: 'number',
          required: true,
          message: '请设置通过比例',
          trigger: 'blur'
        }
      },
      userData: {
        departmentIds: [],
        tagIds: [],
        userIds: []
      }
    }
  },
  computed: {
    tobeDeletedName() {
      return this.rowData[this.tobeDeletedIndex] ? this.rowData[this.tobeDeletedIndex].name : ''
    }
  },
  created() {
    this.handleSearchPaper(true)
    this.paperBankName = sessionStorage.getItem('paperBankName')
    this.$watch(
      'searchKey',
      throttle(newQuery => {
        this.$emit('searchKey', newQuery)
        this.handleSearchPaper(true)
      }, 2000)
    )
  },
  watch: {
    disUser: {
      handler(val) {
        if (val === 0) {
          this.relForm.studentIds = []
        }
      },
      immediate: true
    },
    'relForm.repeatState'(val) {
      if (!val) {
        this.relForm.repeatNum = null
      }
    }
  },
  methods: {
    validateNum(num) {
      if (Number(num) < 0 || num === '') return (this.relForm.repeatNum = 1)
      if (parseFloat(num).toString() === 'NaN') {
        this.relForm.repeatNum = null
        return
      }
      this.relForm.repeatNum = parseInt(num) // 取整
    },
    onsuccess(userId, data) {
      this.relForm.studentIds = userId
      this.userData = data
    },
    //复制试卷
    CopyPaper(data) {
      this.$refs.CopyPaper.open(data)
    },

    choseDisCuser(val) {
      if (val) {
        this.relForm.scope = 1
      } else {
        this.relForm.scope = 0
      }
    },
    handleSelect(val) {
      this.relForm.studentIds = []
      val.forEach(v => {
        this.relForm.studentIds.push(v.userId)
      })
    },
    handlePageNum(val) {
      this.deUserListQ.page = val - 1
      this.getdePUsers()
    },
    handlePageSize(val) {
      this.deUserListQ.page = 0
      this.deUserListQ.size = val
      this.getdePUsers()
    },
    chosUsers() {
      this.$refs.StaffSelect.open(false, '', this.userData)
      // let payload = this.deUserListQ
      // payload.IsWait = true
      // if (this.disUser) {
      //   departmentAPI.getDepartmentsTree().then(res => {
      //     if (res.res.departmentId) {
      //       payload.departmentId = res.res.departmentId
      //       this.getdePUsers()
      //     }
      //   })
      // } else {
      //   this.userModal = false
      //   payload.IsWait = false
      // }
    },
    getdePUsers() {
      let searchStaffForm = {
        keyword: this.deUserListQ.keyword,
        departmentId: null,
        status: 0
      }
      staffApi.searchStaffs(searchStaffForm, this.deUserListQ.page, this.deUserListQ.size).then(res => {
        if (res.code === 0) {
          this.userModal = true
          this.depaUsers = res.res.data
          if (this.relForm.studentIds.length !== 0) {
            this.sortSelUsers(this.depaUsers, this.mulSelUsers)
          }
          this.deTotal = res.res.total
        }
        this.deUserListQ.IsWait = false
      })
    },
    sortSelUsers(dU, mU) {
      for (let i = 0; i < dU.length; i++) {
        for (let j = 0; j < mU.length; j++) {
          if (dU[i].userId === mU[j]) {
            this.$nextTick(() => {
              this.$refs.deUsersTable.toggleRowSelection(this.depaUsers[i], true)
            })
          }
        }
      }
    },
    toPaperDesignated() {
      this.$router.push({
        path: '/manage/exam/paperDesignated',
        query: {
          tabLabel: this.paperBankName,
          paperBankId: this.paperBankId
        }
      })
    },
    delPapers1() {
      if (this.selectedExamId.length === 0) {
        this.$message.error('请选择要删除的试卷')
      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确认删除试卷吗？</p>',
          loading: true,
          onOk: () => {
            let ids = []
            this.selectedExamId.map(item => {
              ids.push(item)
            })
            PaperAPI.newPaperByIds(ids).then(res => {
              if (res.res === true) {
                this.selectedExamId = []
                this.$message.success('删除成功')
                this.$Modal.remove()
                this.handleSearchPaper()
              } else {
                this.$Modal.remove()
              }
            })
          }
        })
      }
    },
    changeSize(val) {
      this.pageSize = val
      this.handleSearchPaper()
    },
    handlePageChange(page) {
      this.currentPage = page - 1
      this.handleSearchPaper()
    },
    pushIds(sel) {
      this.delIds = sel
    },
    checkLevel(num) {
      return difficulty[num].label
    },
    toDetail(row) {
      if (row.type === 4) {
        PaperAPI.getRandomPostPaperId(row.paperId).then(res => {
          if (res.code === 0) {
            this.$router.push({
              path: `/manage/paper/edit`,
              query: {
                paperId: res.res,
                paperBankId: row.paperBankId,
                isRandom: true
              }
            })
          }
        })
      } else {
        this.$router.push({
          path: '/manage/paper/detail',
          query: {
            id: row.paperId
          }
        })
      }
    },
    handleSee(row) {
      this.$router.push({
        path: '/manage/exam/IndividualPapers',
        query: {
          tabLabel: this.paperBankName,
          paperBankId: row.paperBankId,
          id: row.paperId,
          exam: true,
          name: row.name,
          IsExamLibrary: true
        }
      })
    },
    selectedChange(val) {
      this.selectedExamId = val.map(item => {
        return item.paperId
      })
    },
    handleRel(row) {
      this.row = row
      this.showRel = true
      this.relForm.name = row.name
      this.relForm.paperId = row.paperId
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    confirmRel(form) {
      if (this.relForm.repeatState === 1 && (this.relForm.repeatNum === null || this.relForm.repeatNum === undefined))
        return this.$message.warning('重考次数必填')
      this.$refs[form].validate(val => {
        if (val) {
          if (
            this.relForm.endTime !== '' &&
            this.relForm.startTime !== '' &&
            this.relForm.endTime !== null &&
            this.relForm.startTime !== null &&
            this.relForm.endTime <= this.relForm.startTime
          ) {
            return this.message('结束时间请不要' + (this.relForm.endTime < this.relForm.startTime ? '早于' : '等于') + '开始时间', 'warning')
          }
          if (this.disUser && this.relForm.studentIds.length === 0) {
            return this.$message({
              message: '请选择指定的用户',
              type: 'warning'
            })
          }
          this.IsRelease = true
          ExamApi.addExam(this.relForm)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('发布成功')
                this.relForm.startTime = ''
                this.relForm.endTime = ''
                this.relForm.studentIds = []
                this.relForm.description = ''
                this.relForm.limitTime = 100
                this.relForm.passScore = 0
                this.disUser = 0
                this.showRel = false
                this.handleSearchPaper(true)
                this.$refs.deUsersTable.clearSelection()
                this.$emit('refresh')
                this.IsRelease = false
              }
            })
            .catch(() => {
              this.IsRelease = false
            })
        }
      })
    },
    cancelRel() {
      this.userData.departmentIds = []
      this.userData.tagIds = []
      this.userData.userIds = []
      this.relForm.startTime = ''
      this.relForm.endTime = ''
      this.relForm.description = ''
      this.relForm.limitTime = 1
      this.relForm.passScore = 0
      this.relForm.name = ''
      this.disUser = 0
      this.showRel = false
      if (this.relForm.studentIds.length > 0) {
        this.$refs.deUsersTable.clearSelection()
      }
    },
    closedDeUsers() {
      this.deUserListQ.keyword = ''
    },
    close(done) {
      done()
    },
    ...mapMutations('question', ['reset', 'setSelectedQuestionList']),
    fetchPaperList() {
      this.tableLoading = true
      const bankId = Number.parseInt(this.$route.params.id)
      PaperAPI.getPaperList(bankId, this.currentPage, this.pageSize)
        .then(({ res }) => {
          this.rowData = res.data
          this.totalPage = res.total
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },
    handleRowClicked(row) {
      this.$router.push({
        path: '/exam/IndividualPapers',
        query: {
          tabLabel: this.$route.query.tabLabel,
          paperBankId: row.paperBankId,
          id: row.paperId,
          exam: true,
          name: row.name,
          IsExamLibrary: true
        }
      })
    },
    async handleDelete() {
      const { res } = await PaperAPI.newPaperByIds([this.paperId])
      const usedExams = res
      if (usedExams === true) {
        this.$message.success('试卷删除成功')
        this.handleSearchPaper()
      } else {
        this.$Modal.error({
          title: '不能删除',
          content: `已经有已开始的考试使用该试卷`
        })
      }
    },
    delPaper(index) {
      this.tobeDeletedIndex = index
      const paper = this.rowData[index]
      this.paperId = paper.paperId
      this.$refs.deletePaperBankModal.open()
    },
    async doDelete() {
      const paper = this.rowData[this.tobeDeletedIndex]
      const { res } = await PaperAPI.getPaperRelativeExams(paper.paperId)
      const usedExams = res.filter(exam => exam.status !== EXAM_STATUS.NEWLY)
      const { paperId } = this.rowData[this.tobeDeletedIndex]
      this.isProcessingDelete = true
      try {
        await PaperAPI.deletePaperByPaperId(paperId).then(() => {
          if (usedExams.length > 0) {
            this.$Modal.error({
              title: '不能删除',
              content: `已经有已开始的考试使用该试卷：\n${usedExams.map(exam => `<p>${exam.name}</p>`).join(', ')}`
            })
          } else {
            // this.fetchPaperList()
            this.$message.success('试卷删除成功')
            this.handleSearchPaper()
          }
        })
      } catch (e) {
      } finally {
        this.isProcessingDelete = false
        this.showDeleteConfirmation = false
      }
    },
    getStatusName(index) {
      const nameMap = ['UNKNOWN', '正常', '草稿', '已删除', '已删除']
      return nameMap[index]
    },
    getStatusColor(index) {
      const colorMap = ['#CBCCCC', '#4CE47A', '#CBCCCC', '#DA2E2E', '#DA2E2E']
      return colorMap[index]
    },
    handleDropdownClicked(name) {
      const { id } = this.$route.params
      this.reset()
      // if (name === 'manually') {
      this.$store.commit('question/setEditorData', {
        isClassify: false,
        currentIndex: '',
        type: 6
      })
      if (name === 'manual') {
        this.$router.push({
          path: '/manage/paper/editor',
          query: { bankId: id }
        })
      } else if (name === 'auto') {
        //
        this.toPath(id, true)
      } else if (name === 'rand') {
        this.toPath(id, false)
      }
    },
    toPath(id, IsAuto, paperId) {
      this.bankId = id
      if (IsAuto) {
        this.$router.push({
          path: '/manage/paper/auto',
          query: { bankId: id }
        })
      } else {
        this.$refs.Random.open()
        // this.$router.push({
        //   name: 'paperAuto',
        //   params: {
        //     bankId: id
        //   }
        // })
      }
    },
    checkStartTime(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.relForm.startTime = ''
      }
    },
    checkStartDate(date) {
      return Date.now() > date.getTime() + 24 * 60 * 60 * 1000
    },
    checkEndDate(date) {
      if (new Date().getTime() > date.getTime() + 5000) {
        this.$message.error('请不要选择过去的时间')
        this.relForm.endTime = ''
      }
    },
    async handleSearchPaper(refresh = false) {
      if (refresh) {
        this.currentPage = 0
        this.pageSize = 10
      }
      const { res } = await PaperAPI.searchAllPaper(this.currentPage, this.pageSize, {
        paperBankId: this.$route.params.id,
        name: this.searchKey
      })
      this.rowData = res.data
      this.activeIndex = res.data[0].isOwner
      this.totalPage = res.total
      res.data.map(item => {
        this.paperBankId = item.paperBankId
      })
    }
  },
  components: {
    JYCard,
    JYSimpleButton,
    JYStatus,
    DeleteModal,
    PaperBankCard,
    YTIcon,
    ConfirmDialog,
    CopyPaperCUDialog,
    StaffSelectDialog,
    RandomDialog
  }
}
</script>
<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
.yt-container {
  height: calc(100% - 48px);
}
</style>
